import React from "react";

import HelpCenterItem from "../../containers/HelpCenter/HelpCenterItem";

const HelpCenterArticle = ({ path }) => {
  return (
    <HelpCenterItem path={path}>
      <div>
        <ul>
          <li>This feature is only available for Premium members.</li>
          <li>
            Sync Progress button reads your latest progress from the cloud and
            merges with your local progress.
          </li>
          <li>
            Press the "Sync Progress" button on the device you've been using.
            Then on the new device, login to the same account and make sure you
            have an active connection. You can also manually tap “Sync Progress”
            on the second device to get the latest data.
          </li>
          <li>
            If you are using the app without logging in, any progress you have
            in your device will be merged to the first account you login.
          </li>
        </ul>
      </div>
    </HelpCenterItem>
  );
};

export default HelpCenterArticle;
